/* .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; 
} */

@media screen and (max-width: 600px) {
  img {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  /* .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  } */
}

@media screen and (min-width: 600px) {
  img {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  /* .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  } */
}

/* img {
  width: 50%
} */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: gray;
  color: white;
  text-align: center;
  font-family: 'Courier New', Courier, monospace
}